import React from "react";
import Link from "next/link";
import { LatestGameCard } from "./LatestGamesWidget";
import FooterSideBarAd from "./ads/FooterSidebar";

export const Footer = ({ data }) => {
  if (!data) {
    return null;
  }
  return <>
    <div className="bg-light mt-5">
      <div className="responsive py-5 flex-col md:flex-row">
        <div className="mr-5 mb-5 md:w-1/4">
          <span className="block text-xl font-light uppercase mb-4">
            Recent Games
          </span>
          {data.posts.map((node, index) => {
            if (
              !node.images?.find(
                (size) => 150 === size.width
              ) ||
              index > 3
            ) {
              return null;
            }
            return (
              <LatestGameCard
                key={node.uri}
                lazy={false}
                bg={false}
                data={node}
              />
            );
          })}
        </div>
        <div className="mr-5 mb-5 md:w-1/4">
          <span className="block text-xl font-light uppercase mb-4">
            Terms
          </span>
          {data.legalPages.map((page) => (
            (<Link
              href={`/legal/${page.post_name}`}
              key={page.ID}
              className="block text-sm hover:text-primary transition-colors mt-2"
            >
              {page.post_title}
            </Link>)
          ))}
        </div>
        <div className="mr-5 mb-5 md:w-1/4">
          <span className="block text-xl font-light uppercase mb-4">
            Contact
          </span>
          <Link
            href={"/"}
            className="block text-sm hover:text-primary transition-colors mt-2">

            RadCade

          </Link>
          <span className="block text-sm mt-2">
            email admin at radcade.com
          </span>
          <div className="flex items-center py-3">
            <a
              title="Facebook"
              target="_blank"
              href="https://www.facebook.com/The-RadCade-106180331165193"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-5 w-5 fill-current text-white mr-2"
              >
                <path d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h192V336h-64v-80h64v-64c0-53.024 42.976-96 96-96h64v80h-32c-17.664 0-32-1.664-32 16v64h80l-32 80h-48v176h96c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z" />
              </svg>
            </a>
            <a
              title="Twitter"
              target="_blank"
              href="https://twitter.com/TheRadCade"
            >
              <svg
                className="h-5 w-5 fill-current text-white mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 438 438"
              >
                <path d="M414.41 24.123C398.333 8.042 378.963 0 356.315 0H82.228C59.58 0 40.21 8.042 24.126 24.123 8.045 40.207.003 59.576.003 82.225v274.084c0 22.647 8.042 42.018 24.123 58.102 16.084 16.084 35.454 24.126 58.102 24.126h274.084c22.648 0 42.018-8.042 58.095-24.126 16.084-16.084 24.126-35.454 24.126-58.102V82.225c-.001-22.649-8.043-42.021-24.123-58.102zm-78.939 144.612c.191 1.713.288 4.278.288 7.71 0 15.989-2.334 32.025-6.995 48.104-4.661 16.087-11.8 31.504-21.416 46.254-9.606 14.749-21.074 27.791-34.396 39.115-13.325 11.32-29.311 20.365-47.968 27.117-18.648 6.762-38.637 10.143-59.953 10.143-33.116 0-63.76-8.952-91.931-26.836 4.568.568 9.329.855 14.275.855 27.6 0 52.439-8.565 74.519-25.7-12.941-.185-24.506-4.179-34.688-11.991-10.185-7.803-17.273-17.699-21.271-29.691 4.947.76 8.658 1.137 11.132 1.137 4.187 0 9.042-.76 14.56-2.279-13.894-2.669-25.598-9.562-35.115-20.697-9.519-11.136-14.277-23.84-14.277-38.114v-.571c10.085 4.755 19.602 7.229 28.549 7.422-17.321-11.613-25.981-28.265-25.981-49.963 0-10.66 2.758-20.747 8.278-30.264 15.035 18.464 33.311 33.213 54.816 44.252 21.507 11.038 44.54 17.227 69.092 18.558-.95-3.616-1.427-8.186-1.427-13.704 0-16.562 5.853-30.692 17.56-42.399 11.703-11.706 25.837-17.561 42.394-17.561 17.515 0 32.079 6.283 43.688 18.846 13.134-2.474 25.892-7.33 38.26-14.56-4.757 14.652-13.613 25.788-26.55 33.402 12.368-1.716 23.88-4.95 34.537-9.708-7.993 12.181-17.989 22.554-29.98 31.123z" />
              </svg>
            </a>
          </div>
        </div>
        <div
          className="mr-5 mb-5 md:w-1/4 h-auto"
          style={{ width: 270 }}
        >
          <FooterSideBarAd />
        </div>
      </div>
    </div>
    <div className="text-center pb-3 pt-4">
      <a
        href="https://redtrench.com/"
        className="text-sm font-bold hover:underline"
      >
        RadCade is a Red Trench Media Corp Property
      </a>
    </div>
  </>;
};
