import React from "react";
import Link from "next/link";
import { TopMenu } from "./TopMenu";
import { LatestGamesWidget } from "./LatestGamesWidget";
import { NewsWidget } from "./NewsWidget";
import BannerContentAd from "./ads/BannerContent"

export const Header = ({ data }) => {
  return (
    <>
      <div className="bg-block border-b border-black shadow-block z-30">
        <div className="responsive py-3">
          <svg
            className="w-4 h-4 mr-2 fill-current text-primary"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 53.626 53.626"
          >
            <path d="M48.831 15.334c-7.083-11.637-17.753-3.541-17.753-3.541-.692.523-1.968.953-2.835.955l-2.858.002c-.867.001-2.143-.429-2.834-.952 0 0-10.671-8.098-17.755 3.539C-2.286 26.97.568 39.639.568 39.639c.5 3.102 2.148 5.172 5.258 4.912 3.101-.259 9.832-8.354 9.832-8.354.556-.667 1.721-1.212 2.586-1.212l17.134-.003c.866 0 2.03.545 2.585 1.212 0 0 6.732 8.095 9.838 8.354 3.106.26 4.758-1.812 5.255-4.912-.001 0 2.858-12.667-4.225-24.302zm-28.457 9.472H16.7v3.541s-.778.594-1.982.579c-1.202-.018-1.746-.648-1.746-.648v-3.471h-3.47s-.433-.444-.549-1.613c-.114-1.169.479-2.114.479-2.114h3.675v-3.674s.756-.405 1.843-.374a4.86 4.86 0 011.885.443l-.015 3.604h3.47s.606.778.656 1.718c.05.941-.572 2.009-.572 2.009zm16.852 4.036a2.904 2.904 0 01-2.906-2.908 2.902 2.902 0 012.906-2.908 2.909 2.909 0 110 5.816zm0-8.001a2.903 2.903 0 01-2.906-2.907 2.902 2.902 0 012.906-2.908 2.909 2.909 0 012.909 2.908 2.91 2.91 0 01-2.909 2.907zm7.242 4.295a2.903 2.903 0 01-2.906-2.908 2.903 2.903 0 012.906-2.908 2.91 2.91 0 012.909 2.908 2.91 2.91 0 01-2.909 2.908z" />
          </svg>
          <span className="text-xs font-bold">
            {data?.category?.name && data.category.name}
            {data?.postBy?.title && data.postBy.title}
            {!data?.category?.name &&
              !data?.postBy?.title &&
              data.settings.blogdescription}
          </span>
        </div>
      </div>
      <div className="bg-light">
        <div className="responsive py-5 items-center flex-col md:flex-row min-w-0">
          <div
            className="flex items-center flex-col lg:flex-row"
            itemScope="itemscope"
            itemType="http://schema.org/WPHeader"
          >
            <Link href="/" legacyBehavior>
              <a className="flex-shrink-0" title="RadCade" itemProp="url">
                <img
                  src="/assets/logo.png"
                  alt="RadCade logo"
                  itemProp="image"
                  title="RadCade"
                  width={300}
                  height={200}
                />
              </a>
            </Link>
            <p className="text-4xl text-shadow text-center">
              Totally <strong>Rad</strong> web games! <br /> and indie game
              content!
            </p>
          </div>

          <div
            className="block md:ml-auto flex-shrink-0 mt-4 md:mt-0 h-auto"
            style={{ width: 400 }}>
            <BannerContentAd />
          </div>
        </div>
      </div>
      <div className="bg-block">
        <div className="responsive">
          <TopMenu data={data} />
        </div>
      </div>
      <div className="bg-block-light">
        <div className="responsive">
          <LatestGamesWidget data={data} />
        </div>
      </div>
      <div className="bg-block">
        <div className="responsive items-center">
          <NewsWidget data={data} />
        </div>
      </div>
    </>
  );
};
