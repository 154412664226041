import { decode } from "html-entities";

export function removeTags(str) {
  if (str === null || str === "" || str === undefined) return "";
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  const rmTags = str.replace(/(<([^>]+)>)/gi, "");
  return decode(rmTags, { level: "html5" });
}
