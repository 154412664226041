import React, { useRef } from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { removeTags } from "../util/removeTags";

export const NewsWidget = ({ data }) => {
  const swiperRef = useRef(null);
  return (
    <>
      <div className="min-w-0 flex-shrink-0 w-full flex h-10">
        <div className="font-bold uppercase mr-5 text-sm flex items-center bg-block-light news-badge pr-3">
          <svg
            height="16"
            viewBox="0 0 192 192"
            width="16"
            fill="rgb(185, 213, 94)"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M155.109 74.028a4 4 0 00-3.48-2.028h-52.4l8.785-67.123a4.023 4.023 0 00-7.373-2.614L36.917 113.905A4 4 0 0040.324 120h51.617l-6.962 67.224a4.024 4.024 0 007.411 2.461l62.671-111.63a4 4 0 00.048-4.027z" />
          </svg>
          <span className="ml-1.5">News</span>
        </div>
        <Swiper
          direction="vertical"
          ref={swiperRef}
          spaceBetween={10}
          slidesPerView={1}
          slidesPerGroup={1}
          loop={true}
        >
          {data.posts.map((node, index) => {
            return (
              <SwiperSlide key={node.uri}>
                <NewsCard data={node} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button
          aria-label="prev"
          className="focus:outline-none ml-2"
          onClick={() => swiperRef.current.swiper.slidePrev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          aria-label="next"
          className="focus:outline-none ml-2"
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export const NewsCard = ({ data }) => {
  return (
    <div
      className="p-2 pt-2.5 flex relative w-full items-center whitespace-nowrap min-w-0 w-full"
      itemScope="itemscope"
      itemType="http://schema.org/Article"
    >
      <Link
        href="/"
        className="text-sm font-bold hover:underline text-primary mr-1"
        title={data.title}
        itemProp="url"
      >

        {data.title}

      </Link>
      <span
        className="text-sm overflow-ellipsis w-full block whitespace-nowrap min-w-0 overflow-hidden"
        itemProp="description"
      >
        {removeTags(data.excerpt) + "..."}
      </span>
    </div>
  );
};
