import React from "react";
import useAdsByGoogle from "./useAdsByGoogle"

export default function BannerContentAd() {
  useAdsByGoogle();
  return (
    < ins className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-4646414058040259"
      data-ad-slot="3361179215"
      data-ad-format="auto"
      data-full-width-responsive="true" />
  );
}
