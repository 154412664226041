import React from "react";
import useAdsByGoogle from "./useAdsByGoogle"

export default function FooterSidebarAd() {
  useAdsByGoogle();
  // Footer Sidebar
  return (
    <ins className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-4646414058040259"
      data-ad-slot="1585431297"
      data-ad-format="auto"
      data-full-width-responsive="true" />
  );
}
