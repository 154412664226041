import React, { useState } from "react";
import Link from "next/link";
import classNames from "classnames";

export const TopMenu = ({ data }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="flex flex-col md:flex-row w-full py-3 md:p-0">
      <button
        className="uppercase font-bold bg-block-light hover:bg-primary px-5 py-2.5 text-sm md:hidden w-full focus:outline-none"
        onClick={() => setOpened(!opened)}
      >
        Menu
      </button>
      {data.menu.map((node, index) => {
        return (
          <Link
            href={node.path}
            key={`top-menu-${index}`}
            className={classNames(
              "uppercase font-bold hover:bg-block-light px-5 py-2.5 text-sm w-full md:w-auto text-center md:block",
              {
                hidden: !opened,
              }
            )}>
            {node.label}
          </Link>
        );
      })}
    </div>
  );
};
