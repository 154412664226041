import React, { useRef, useState } from "react";
import Link from "next/link";
import SwiperCore, { Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { noImageUrl } from "../config";

SwiperCore.use([Lazy]);

export const LatestGamesWidget = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef(null);

  return (
    <div className="flex-grow my-2 min-w-0 flex-shrink-0 w-full flex">
      <button
        aria-label="prev"
        className={classNames("focus:outline-none mr-2", {
          hidden: activeIndex === 0,
        })}
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <Swiper
        ref={swiperRef}
        onSlideChange={(e) => setActiveIndex(e.activeIndex)}
        spaceBetween={10}
        slidesPerView={5}
        slidesPerGroup={5}
        lazy={true}
        breakpoints={{
          100: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          430: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          640: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          900: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          1100: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
        }}
      >
        {data.posts.map((node, index) => {
          if (
            !node.images?.find(
              (size) => 150 === size.width
            ) ||
            index > 21 ||
            index < 1
          ) {
            return null;
          }
          return (
            <SwiperSlide key={node.uri}>
              <LatestGameCard data={node} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <button
        aria-label="next"
        className={classNames("focus:outline-none ml-2", {
          hidden: activeIndex >= 15,
        })}
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export const LatestGameCard = ({ bg = true, data, lazy = true }) => {
  if (!data) return null;
  const image =
    data.images?.find(
      (image) => 150 === image.width || 150 === image.height
    ) ||
    data.featured_image_url ||
    data.mabp_screen1_url ||
    noImageUrl;

  if (!image) {
    return null;
  }

  return (
    <div
      className={classNames("p-2 inline-flex relative w-full h-18", {
        "bg-block": bg,
        "pl-0": !bg,
      })}
      itemScope="itemscope"
      itemType="http://schema.org/Article"
    >
      <Link
        href={data.uri}
        className="flex-shrink-0 relative"
        title={data.title}
        itemProp="url"
      >

        {lazy ? (
          <>
            <img
              className="block swiper-lazy"
              width="60"
              height="60"
              data-src={image?.sourceUrl ? image?.sourceUrl : image}
              alt={data.title}
            />
            <div
              className="bg-block swiper-lazy-preloader swiper-lazy-preloader-white absolute left-0 top-0"
              style={{ height: 60, width: 60 }}
            />
          </>
        ) : (
          <img
            className="block"
            width="60"
            height="60"
            src={image?.sourceUrl ? image?.sourceUrl : image}
            alt={data.title}
          />
        )}

      </Link>
      <div className="ml-2 min-w-0">
        <Link
          href={data.uri}
          title={data.title}
          className="text-xs link font-bold block mb-1.5 block whitespace-nowrap overflow-hidden overflow-ellipsis"
          itemProp="url"
        >
          {data.title}
        </Link>
        <p className="text-xs link mb-1.5 block whitespace-nowrap overflow-hidden overflow-ellipsis">{data.categories.map(cat => cat.name).join(',')}</p>
      </div>
    </div>
  );
};
