import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { ScrollTop } from "./ScrollTop";

export const Layout = ({ children, data }) => {
  return (
    <>
      <Header data={data} />
      {children}
      <ScrollTop />
      <Footer data={data} />
    </>
  );
};
