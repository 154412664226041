import { useEffect } from "react";

export default function useAdsByGoogle() {
  useEffect(() => {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (ex) {
      console.warn('failed to push adblock for google');
    }
  }, []);
}
